@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@400;500;700&display=swap');

// COLORS
$bodyClr: #1f1f1f;
// $firstClr: #0082c8;
$firstClr: rgb(249, 45, 79);
$btnClr: #3d3d3d;
$btnClrLg: #474747;
$titleClr: #f2f2f2;
$textClr: #bfbfbf;
$textClrLight: #8c8c8c;
$containerClr: #000000;
$borderCrl: #333333;

// FONTS
$bodyFont: "Raleway";
$fontXlg: 4rem;
$fontLg: 3rem;
$fontSlg: 1.5rem;
$fontMd: 1rem; //normal-font-size: 1rem;
$fontSm: .875rem; //small-font-size

// FONT WEIGHTS
$weightMd: 500;
$weightLg: 700;
$weightXlg: 900;

// MARGIN BOTTOM
$mb-0-5: .5rem;
$mb-0-75: .75rem;
$mb-1: 1rem;
$mb-1-5: 1.5rem;
$mb-2: 2rem;
$mb-2-5: 2.5rem;
$mb-3: 3rem;

// SHADOWS
$box-shadow-1: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;
$box-shadow-2:rgba(0, 0, 0, 0.25) 0px 54px 55px, 
rgba(0, 0, 0, 0.12) 0px -12px 30px, 
rgba(0, 0, 0, 0.12) 0px 4px 6px, 
rgba(0, 0, 0, 0.17) 0px 12px 13px, 
rgba(0, 0, 0, 0.09) 0px -3px 5px;

$text-shadow-1: .5rem .5rem .2rem $bodyClr;
