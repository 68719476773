@use "../../style/mixins";
@use "../../style/variables" as *;

.header {
    width: 100%;
    background-color: transparent;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;

    &.scroll-header {
        background-color: $btnClrLg;
        box-shadow: 0 1px 4px hsla(0, 4%, 15%, .10);
    }
}

.nav {
    height: 4.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include mixins.breakpoint-up(medium){
        column-gap: 3rem;
    }

    &__logo {
        margin-right: 1rem;
        transform: rotate(-30deg);

        @include mixins.breakpoint-up(medium) {
            & img {
                width: 60px;
                height: 60px;
            }
        }
    }

    &__menu {
        transition: .5s;

        @include mixins.breakpoint-down(medium){
            position: fixed;
            background-color: $btnClrLg;
            top: 0;
            right: -100%;
            width: 100%;
            height: 100%;
            padding: 6rem 0;
        }

        &.show-menu {
            right: 0;
            transition: right .5s;
        }
    }

    .nav__list {
        display: flex;
        flex-direction: column;
        align-items: center;
        row-gap: 4rem;

        @include mixins.breakpoint-up(medium){
            flex-direction: row;
            justify-content: flex-start;
            column-gap: 3rem;
        }

        @include mixins.breakpoint-up(medium) {
            column-gap: 4rem;
        }
    }

    .nav__link {
        color: $titleClr;
        font-weight: $weightLg;
        text-transform: uppercase;
        font-size: $fontSlg;
        transition: .3s;

        @include mixins.breakpoint-up(medium){
            font-size: $fontMd;
            transition: none;
        }

        @include mixins.breakpoint-up(large){
            font-size: 1.3rem;
        }

        &:hover {
            text-decoration: underline;
            transform: .3s;
        }
    }

    @include mixins.breakpoint-up(medium){
        .nav__close,
        .nav__toggle {
            cursor: pointer;
            display: none;
            
        }
    }

    .nav__close {
        position: absolute;
        top: .9rem;
        right: 1.25rem;
        background: transparent;
    }

    .nav__toggle {
        background: transparent;
    }
}